import axios from 'axios';
import moment from 'moment';
import {
    AccountData,
    AnswerData,
    AnswerFromDatabase,
    clientQuestionsToSendToDatabase,
    EmailClientData,
    EmailData,
    EMPTY_ACCOUNT,
    EMPTY_ANSWER,
    LoginResponse
} from "./intefaces";

const BASE_API_ROUTE = '/databaseOps';

async function makeApiCallWithInput<T = any>(endpoint: string, data: any): Promise<T> {
    try {
        const response = await axios.post(`${BASE_API_ROUTE}${endpoint}`, data)
        return response.data as T;
    } catch (error) {
        throw new Error(handleSystemError(error));
    }
}

async function makeApiCall<T = any>(endpoint: string): Promise<T> {
    try {
        const response = await axios.post(`${BASE_API_ROUTE}${endpoint}`)
        return response.data as T;
    } catch (error) {
        throw new Error(handleSystemError(error));
    }
}

const handleSystemError = (error: any) => {
    if (axios.isAxiosError(error)) {
        return 'Axios error: ' + error.message;
    } else {
        return 'Error: ' + error.message;
    }
}

export const sendQuestionsToDatabase = async (id: number, q: string[]): Promise<string> => {
    const data: clientQuestionsToSendToDatabase = {
        clientId: id,
        created: moment().startOf('day').valueOf(),
        question1: q[0],
        question2: q[1],
        question3: q[2]
    }

    return await makeApiCallWithInput('/ProphetAddClientRequest.php', data);
}

export const setReadByClientState = async (id: number, readAt: moment.Moment): Promise<string> => {
    const data = {id: id, readAt: readAt.valueOf()};
    return await makeApiCallWithInput('/ProphetUpdateClientRequest.php', data);
}

const convertTimeStampToMomentDate = (t: bigint): moment.Moment => {
    return moment(Number(t));
}

export const readNextUnreadAnswerFromDatabase = async (acc: AccountData): Promise<AnswerData> => {

    const answerFromBackend: AnswerFromDatabase = await makeApiCallWithInput('/ProphetGetClientRequest.php', {id: acc.id});

    if (!answerFromBackend) return EMPTY_ANSWER;

    return {
        id: answerFromBackend.id,
        account: acc,
        questions: [answerFromBackend.question1,
            answerFromBackend.question2,
            answerFromBackend.question3],
        numbers: [answerFromBackend.answer1,
            answerFromBackend.answer2,
            answerFromBackend.answer3],
        verseName: answerFromBackend.verseName,
        verseText: answerFromBackend.verseText,
        advice: answerFromBackend.advice,
        videoInfo: answerFromBackend.videoInfo,
        videoLink: answerFromBackend.video,
        answeringProphet: answerFromBackend.prophet,
        answerDateTimestamp: convertTimeStampToMomentDate(answerFromBackend.answered),
        readByClientTimeStamp: convertTimeStampToMomentDate(answerFromBackend.readByClient)
    }
}

export const createAccount = async (data: EmailClientData): Promise<string> => {
    const response = await makeApiCallWithInput('/ProphetClientAddUser.php', data);
    return response.msg;
};

export const processUserLogin = async (name: string, pwd: string): Promise<AccountData> => {
    const loginResponse: LoginResponse = await makeApiCallWithInput('/ReitbetriebLogin.php', {
        name,
        pwd
    });
    return loginResponse.msg.includes('angemeldet') ? await getAccount() : EMPTY_ACCOUNT;
};

export const getAccount = async (): Promise<AccountData> => {
    return await makeApiCall('/showLogin.php');
}

export const processUserLogout = async (): Promise<string> => {
    const response = await makeApiCall('/ReitbetriebLogout.php');
    return response.msg;
}

export const sendEmail = async (data: EmailData): Promise<string> => {
    return await makeApiCallWithInput('/ProphetClientAppSendEmail.php', data);
};

export const resetPasswordInDatabase = async (email: string, newPwd: string): Promise<string> => {
    return await makeApiCallWithInput('/ProphetResetPassword.php', {email: email, pwd: newPwd});
}

export const deleteAccount = async () :Promise<string> => {
    return await makeApiCall('/ProphetDeleteAccount.php');
}

export function generateRandomHash(length: number) {
    const validChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()-_=+{}[]';
    let array = new Uint8Array(length);
    window.crypto.getRandomValues(array);
    let result = '';
    for (let i = 0; i < length; i++) {
        result += validChars.charAt(array[i] % validChars.length);
    }
    return result;
}