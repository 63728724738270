import {AccountData, AnswerData, EmailClientData, EmailData} from "./intefaces";
import {sendEmail} from "./theBackendInterface";

export const sendReportEmail = async (answer: AnswerData) => {

    const data: EmailData = {
        email: answer.account.email,
        subject: 'Report of your answer',
        message: '<h1>Your answer report</h1>' + formatReportAnswer(answer)
    }

    return await sendEmail(data);
};

const formatReportAnswer = (answer: AnswerData) => {
    return `
      <table style="border: 1px solid black; width: 50%;">
        <tr>
          <td><strong>Question 1:</strong></td>
          <td>${answer.questions[0]} ${answer.numbers[0]}</td>
        </tr>
        <tr>
          <td><strong>Question 2:</strong></td>
          <td>${answer.questions[1]} ${answer.numbers[1]}</td>
        </tr>
        <tr>
          <td><strong>Question 3:</strong></td>
          <td>${answer.questions[2]} ${answer.numbers[2]}</td>
        </tr>
        <tr>
          <td><strong>Vers Name:</strong></td>
          <td>${answer.verseName}</td>
        </tr>
        <tr>
          <td><strong>Vers Text:</strong></td>
          <td>${answer.verseText}</td>
        </tr>
        <tr>
          <td><strong>Advice:</strong></td>
          <td>${answer.advice}</td>
        </tr>
        <tr>
          <td><strong>Prophet:</strong></td>
          <td>${answer.answeringProphet}</td>
        </tr>
        <tr>
          <td><strong>Answered:</strong></td>
          <td>${answer.answerDateTimestamp.format("DD-MM-YYYY")}</td>
        </tr>
      <tr>
          <td><strong>Client:</strong></td>
          <td>${answer.account.name}</td>
        </tr>
      </table>
    `;
}

export const sendSupportEmail = async (account: AccountData, message: string) => {

    const data: EmailData = {
        email: 'waltherj@web.de',
        subject: 'Support request',
        message: message + '\nClient: ' + account.name + ' ' + account.email
    }
    return await sendEmail(data);
};

export const sendPasswordResetEmail = async (email: string, newPwd: string): Promise<string> => {

    const formatAnswer = () => {

        return `
            Hello, <br/><br/>
            We have set your password to: <strong>${newPwd}</strong><br/><br/>
            Please log in with this password and change it as soon as possible to secure your account.<br/><br/>
            Best regards,<br/>
            Your Admin Team
        `;
    }

    const emailDetails: EmailData = {
        email: email,
        subject: 'Password reset request',
        message: formatAnswer()
    }

    return await sendEmail(emailDetails);

}

export const sendRegistrationVerificationEmail = async (newClient: EmailClientData): Promise<string> => {
    const formatAnswer = (clientData: EmailClientData) => {

        const {vorname, nachname, password} = clientData;
        return `
            Hello ${vorname} ${nachname},<br/><br/>
            This is your initial password: <strong>${password}</strong><br/><br/>
            Please log in with this password and change it as soon as possible to secure your account.<br/><br/>
            Best regards,<br/>
            Your Admin Team
        `;
    }

    const emailDetails: EmailData = {
        email: newClient.email,
        subject: 'Registration Prophet App',
        message: formatAnswer(newClient)
    }

    return await sendEmail(emailDetails);

}