import React, { useState } from 'react';
import {FieldDictionary} from "../backend/intefaces";
import {useTranslation} from "react-i18next";

export type ValidatorFn = (value: string) => string | undefined;

const isValidEmail = (email: string) => {
    let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
}

export const notEmpty = (value: string) => value === '' ? "error.empty" : undefined;
export const notValidEmail = (value: string) => !isValidEmail(value) ? "error.format" : undefined;
export const textLength = (value: string) => (value.length < 8 || value.length > 16) ? "error.length" : undefined;

const useFormFieldManager = (initialState: FieldDictionary) => {
    const [fields, setFields] = useState<FieldDictionary>(initialState);
    const {t} = useTranslation();

    const updateField = (fieldKey: string, value: string, validators: ValidatorFn[] = []) => {
        let error: string | undefined = undefined;

        for (const validator of validators) {
            error = validator(value);
            if (error) break;
        }

        setFields(prevFields => ({
            ...prevFields,
            [fieldKey]: { value, error },
        }));
    };

    const isFormValid = () => {
        return Object.values(fields).every(field => {
            let isValueProvided = Boolean(field.value);
            let isErrorAbsent = !field.error;
            return isErrorAbsent && isValueProvided;
        })
    };

    const getBorderColor = (fieldKey: string) => {
        return fields[fieldKey].error ? 'red' : '';
    };

    const renderError = (fieldName: string) => {
        const f= fields[fieldName].error;
        if (f) {
            return (
                <div style={{color: '#02499d'}}>
                    {t(f)}
                </div>
            )
        }
    }

    return {
        fields,
        updateField,
        getBorderColor,
        renderError,
        isFormValid,
    };
};

export default useFormFieldManager;