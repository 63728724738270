import {generateRandomHash, resetPasswordInDatabase} from '../backend/theBackendInterface';
import {sendPasswordResetEmail} from "../backend/sendEmail";
import {useAuth} from "./useAuth";
import { toast } from 'react-toastify';

export const usePasswordManagement = () => {

    const { account } = useAuth();

    const resetPasswordToDefault = async (email: string) => {
        const newPwd = generateRandomHash(12);
        const response = await resetPasswordInDatabase(email, newPwd);
        showMessageBasedOnResponse(response);
        sendPasswordWasResetEmail(response, email, newPwd);
    }

    const sendPasswordWasResetEmail = (response: string, email: string, newPwd: string) => {
        if (response.includes("success")) {
            void sendPasswordResetEmail(email, newPwd);
        } else {
            toast.error(response);
        }
    }

    const showMessageBasedOnResponse = (response: string) => {
        if (response.includes("success")) {
            toast.success('New password successfully set in database.');
        } else {
            toast.error('Failed to set new password in database.');
        }
    }

    const registerNewPassword = async (pwd: string) => {
        const response = await resetPasswordInDatabase(account.email, pwd);
        toast.info(response);
    }

    return {resetPasswordToDefault, registerNewPassword};
}