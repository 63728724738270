import { createAccount } from '../backend/theBackendInterface';
import { EmailClientData } from '../backend/intefaces';
import {sendRegistrationVerificationEmail} from "../backend/sendEmail";
import {toast} from "react-toastify";

export const useRegistration = () => {

    const registerNewClient = async (newClient: EmailClientData) => {

        const response: string = await createAccount(newClient);
        toast.info(response);

        const verification = await sendRegistrationVerificationEmail(newClient);
        console.log('Verification Email send: ',  verification);
        toast.info(verification);
    }

    return { registerNewClient };
}