import {useNavigate} from "react-router-dom";
import React from "react";
import {useTranslation} from "react-i18next";
import {useAuth} from "../custom_hooks/useAuth";

interface ContactDetailsProps {
    name: string;
    address: string;
    phoneNumber: string;
    email: string;
}

const ContactDetails: React.FC<ContactDetailsProps> = ({name, address, phoneNumber, email}) => {
    return (
        <>
            <div>{name}</div>
            <div>{address}</div>
            <div>{phoneNumber}</div>
            <div>{email}</div>
        </>
    )
}

export const Legal = () => {
    const { account} = useAuth();

    const navigate = useNavigate();
    const {t} = useTranslation();

    return (
        <div className="login-container">
            <div className="login-box">
                <h2>{t('legal.legal_notice')}</h2>
                <h3>Angaben gemäß § 5 TMG:</h3>
                <ContactDetails
                    name="Joachim Walther"
                    address="Barriser Weg 64, D-24787 Fockbek"
                    phoneNumber="+49 1523 3697043"
                    email="waltherj@web.de"
                />
                <h3>Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV:</h3>
                <ContactDetails
                    name="Joachim Walther"
                    address="Barriser Weg 64, D-24787 Fockbek"
                    phoneNumber="+49 1523 3697043"
                    email="waltherj@web.de"
                />

                <h2>{t('legal.disclaimer')}</h2>
                <p>Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den
                    allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht
                    verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen
                    zu
                    forschen, die auf eine rechtswidrige Tätigkeit hinweisen.</p>
                <p>Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen
                    Gesetzen
                    bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis
                    einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen
                    werden
                    wir diese Inhalte umgehend entfernen.</p>
                <p>Die Inhalte unserer App dienen ausschließlich der allgemeinen Information und stellen keine Beratung
                    dar.
                    Wir übernehmen keine Haftung für Handlungen, die auf der Grundlage der in unserer App gefundenen
                    Informationen unternommen werden.</p>
                <p>Obwohl wir uns bemühen, die Informationen in unserer App aktuell und genau zu halten, übernehmen wir
                    keine Gewähr für die Aktualität, Richtigkeit, Vollständigkeit oder Qualität der bereitgestellten
                    Informationen.</p>

                <h2>{t('legal.copyright')}</h2>
                <p>Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem
                    deutschen
                    Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb
                    der
                    Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw.
                    Erstellers.
                    Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch
                    gestattet.</p>
                <p>Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte
                    Dritter
                    beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf
                    eine
                    Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei
                    Bekanntwerden
                    von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.</p>
                <h2>{t('legal.privacy_policy')}</h2>
                <p>Die Nutzung unserer Webseite ist in der Regel ohne Angabe personenbezogener Daten möglich. Soweit auf
                    unseren Seiten personenbezogene Daten (beispielsweise Name, Anschrift oder eMail-Adressen) erhoben
                    werden,
                    erfolgt dies, soweit möglich, stets auf freiwilliger Basis. Diese Daten werden ohne Ihre
                    ausdrückliche
                    Zustimmung nicht an Dritte weitergegeben.</p>
                <p>Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei der Kommunikation per E-Mail)
                    Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist
                    nicht möglich.</p>
                <p>Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten Kontaktdaten durch Dritte zur
                    Übersendung von nicht ausdrücklich angeforderter Werbung und Informationsmaterialien wird hiermit
                    ausdrücklich widersprochen. Die Betreiber der Seiten behalten sich ausdrücklich rechtliche Schritte
                    im
                    Falle der unverlangten Zusendung von Werbeinformationen, etwa durch Spam-Mails, vor.</p>
                <h2>{t('legal.source')}</h2>
                <p><a href="http://www.e-recht24.de/muster-disclaimer.htm">eRecht24</a></p>
            </div>
            {account.id === 0 && <button onClick={() => navigate("/")}>{t('general.login')}</button>}
            {account.id !== 0 && <button onClick={() => navigate("/menu")}>{t('menu.main')}</button>}
        </div>
    )
}
