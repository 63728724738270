import React, {useState} from "react";
import {ListOfQuestions} from "../components/listOfQuestions";
import {NewQuestion} from "../components/newQuestion";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useAuth} from "../custom_hooks/useAuth";

export function Questions(props: { onSendQuestions: (d: string[]) => void}) {
    const { account, updateAccount } = useAuth();

    const [questions, setQuestions] = useState<string[]>([]);
    const [newQuestion, setNewQuestion] = useState('');
    const [questionsSend, setQuestionsSend] = useState(false);
    const navigate = useNavigate();
    const buttonStates = [
        { disabled: newQuestion.length === 0 || questions.length === 3 },
        { disabled: questions.length === 0 || questionsSend}
    ];
    const { t } = useTranslation();

    if ( account.id === 0 ) {
        navigate('/');
    }

    const returnToMain = () => {
        void updateAccount();
        navigate('/menu');
    }

    const addQuestion = () => {
        saveNewQuestion(newQuestion);
        setNewQuestion('');
    };

    const saveNewQuestion = (question: string) => {
        if (question.trim() !== '') {
            setQuestions([...questions, question]);
        }
    }

    const removeQuestion = (question: string) => {
        setQuestions(questions.filter((q) => q !== question));
    }

    const sendQuestions = () => {
        props.onSendQuestions(questions);
        setQuestionsSend(true);
    }

    return (
        <div className="login-container">
            <ListOfQuestions questions={questions}
                             numbers={[]}
                             withDelete={!questionsSend}
                             removeQuestion={removeQuestion}
            />
            {questions.length < 3 && <NewQuestion newQuestion={newQuestion} setNewQuestion={setNewQuestion}/>}
            <div className="buttonset">
                <button disabled={buttonStates[0].disabled} onClick={addQuestion}>{t('questions.add_question')}</button>
                <button disabled={buttonStates[1].disabled} onClick={sendQuestions}>{t('questions.send_questions')}</button>
                <button disabled={false} onClick={returnToMain}>{t('menu.main')}</button>
            </div>
        </div>
    )
}