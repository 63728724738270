import React from 'react';
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {z} from "zod";
import {FieldError, useForm, UseFormRegister} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";

const newPasswordSchema = () => z.object({
    password: z.string()
        .min(1, "validation.password_required")
        .min(8, "validation.password_min_length")
        .max(16, "validation.password_max_length"),
    password_again: z.string()
        .min(1, "validation.password_required")
}).refine(data => data.password === data.password_again, {
    message: "validation.passwords_mismatch",
    path: ["password_again"], // this indicates which field will display the error
});

type NewPasswordFormInputs = z.infer<ReturnType<typeof newPasswordSchema>>;

export const NewPwd = (props: { onNewPwd: (pwd: string) => void }) => {

    const navigate = useNavigate();

    const {
        register,
        handleSubmit,
        formState: {errors, isValid}
    } = useForm<NewPasswordFormInputs>({
        resolver: zodResolver(newPasswordSchema()),
        mode: 'onChange'
    });

    const onSubmit = (data: NewPasswordFormInputs) => {

        props.onNewPwd(data.password);

        returnToMainMenu();
    };

    const returnToMainMenu = () => {
        navigate('/menu');
    }

    return (
        <form className="login-container" onSubmit={handleSubmit(onSubmit)}>
            <div className="login-box">
                <NewPasswordInput
                    register={register}
                    name="password"
                    type="password"
                    placeholder='general.password'
                    error={errors.password}
                />

                <NewPasswordInput
                    register={register}
                    name="password_again"
                    type="password"
                    placeholder='general.password_again'
                    error={errors.password_again}
                />

                <div className="buttonset">
                    <Button type="submit" disabled={!isValid} label='general.save_new_password' />
                    <Button type="button" onClick={returnToMainMenu} label='general.back' />
                </div>
            </div>
        </form>
    );
}

interface InputProps {
    register: UseFormRegister<NewPasswordFormInputs>;
    name: keyof NewPasswordFormInputs;
    type: string;
    placeholder: string;
    error?: FieldError;
}

const NewPasswordInput: React.FC<InputProps> = ({ register, name, type, placeholder, error }) => {
    const { t } = useTranslation();

    return (
        <div className="login-inputset">
            <input
                {...register(name)}
                type={type}
                placeholder={t(placeholder)}
                style={{ borderColor: error ? 'red' : '#ddd' }}
            />
            {error && <span style={{ color: "red" }}>{error.message && t(error.message)}</span>}
        </div>
    );
};

interface ButtonProps {
    type: 'button' | 'submit';
    onClick?: () => void;
    disabled?: boolean;
    label: string;
}

const Button: React.FC<ButtonProps> = ({ type, onClick, disabled, label }) => {
    const { t } = useTranslation();

    return (
        <button type={type} onClick={onClick} disabled={disabled}>
            {t(label)}
        </button>
    );
};
