import React from 'react';

const getBackgroundColor = (number: number) => {
    if (number >= 76) return 'rgba(0, 128, 0, 0.7)'; // Green with alpha
    if (number >= 51) return 'rgba(255, 255, 0, 0.7)'; // Yellow with alpha
    if (number >= 26) return 'rgba(255, 0, 0, 0.7)'; // Red with alpha
    if (number >= 1) return 'rgba(20, 20, 20, 0.7)'; // Red with alpha
    return 'rgba(0, 0, 0, 0.2)'; // Black with alpha
};

const getTextColor = (number: number) => {
    if (number >= 26) return 'black'; // Dark text for lighter backgrounds
    if (number >= 1) return 'white'; // Dark text for lighter backgrounds
    return 'blue'; // Light text for darker backgrounds
};

function QuestionItem (props: {question: string, number: number, withDelete: boolean, remove: (question: string) => void}) {
    const backgroundColor = getBackgroundColor(props.number);
    const textColor = getTextColor(props.number);
    const style = {
        backgroundColor,
        color: textColor,
        padding: '10px',
        borderRadius: '5px',
        margin: '10px',
        width: props.withDelete ? '90%' : '100%'
    };

    if (props.question){
    return (
         <div className="question">
            <div style={style}>
                {props.question} {!props.withDelete && props.number && <span>{props.number + 1} %</span>}
            </div>
            {props.withDelete && <button className="deleteQuestionButton" onClick={() => props.remove(props.question)  }>&#x274C;</button>}
        </div>
    )} else {
        return null;
    }
}

export default QuestionItem;
