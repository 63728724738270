import {Message} from "../components/message";
import React from "react";
import {useNavigate} from "react-router-dom";
import '../css/rules.css';
import {useTranslation} from "react-i18next";
import {useAuth} from "../custom_hooks/useAuth";

export const Rules = () => {
    const { account } = useAuth();
    const navigate = useNavigate();
    const {t} = useTranslation();

    const goToMenu = () => {
        navigate('/menu');
    }

    return (
        <div className="rules-box">
            {account.rules.map((rule: string, index: number) => <Message key={index} msg={rule} value={0}/>)}
            <div>
                <button onClick={goToMenu}>{t('menu.main')}</button>
            </div>
        </div>
    )
}