import { toast } from 'react-toastify';
import {AnswerData} from '../backend/intefaces'
import {sendReportEmail, sendSupportEmail} from "../backend/sendEmail";
import {useAuth} from "./useAuth";

export const useSendEmails = () => {
    const { account } = useAuth();

    const sendHelpEmail = async (message: string) => {
        const response = await sendSupportEmail(account, message);
        toast.info(response);
    }

    const sendSummaryEmail = async (answer: AnswerData) => {
        const response: string = await sendReportEmail(answer);
        toast.info(response);
    }

    return {sendHelpEmail, sendSummaryEmail};
}