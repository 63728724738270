import React from 'react';

const DeleteAccountHelp = () => {
    return (
        <div>
            <h3>Delete Account during test phase</h3>
            <p>
                Your account will completely deleted. That includes all account details
                as well as all questions send.<br/>

                This gives you the possibility to repeat the registration process and
                you can send again a block of questions.
            </p>
        </div>
    )
}

export default DeleteAccountHelp;