import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import React from "react";
import '../css/handbook.css';
import {useAuth} from "../custom_hooks/useAuth";

export const Handbook = () => {
    const { account } = useAuth();

    const navigate = useNavigate();
    const {t} = useTranslation();

    const id = account.id;

    if (id === 0) {
        navigate("/");
    }

    return (
        <div className="handbook-box">
            <h3>{t('handbook.handbook')}</h3>
            <p>{t('handbook.handbook_text')}</p>
            <div>
                <button onClick={() => navigate("/menu")}>{t('menu.main')}</button>
            </div>
        </div>
    )
}