import React from "react";
import {useTranslation} from "react-i18next";

export function NewQuestion(props: { newQuestion: string, setNewQuestion: (newQuestion: string) => void}) {
    const { t } = useTranslation();
    return (
        <div className="new-question">
            <input
                type="text"
                value={props.newQuestion}
                onChange={(e) => props.setNewQuestion(e.target.value)}
                placeholder={t("questions.enter_your_question")}
            />
        </div>
    )
}
