import {AnswerData} from "../backend/intefaces";
import {useNavigate} from "react-router-dom";
import React from "react";
import {useTranslation} from "react-i18next";


export const PrivatMenu = (props:{answer: AnswerData, onReturnToMain: () => void, onSendReport: (answer: AnswerData) => void }) => {
    const navigate = useNavigate();
    const {t} = useTranslation();

    const returnToMain = () => {
        props.onReturnToMain();
        navigate('/menu');
    }

    const sendReport = () => {
        props.onSendReport(props.answer);
    }

    return (
        <div className="buttonset">
            <button disabled={props.answer.id === 0} onClick={sendReport}>{t('sendReport.send_report')}</button>
            <button onClick={returnToMain}>{t('menu.main')}</button>
        </div>
    )
}