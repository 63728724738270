import React from "react";
import {useNavigate} from "react-router-dom";

function Modal (props: { show: boolean, onAccept: ()=> void, onReturn: ()=> void, children: React.ReactNode }) {
    const navigate = useNavigate();

    const handleKeyDown = (event: KeyboardEvent) => {
        if (event.key === 'Escape') {
            props.onReturn();
        }
        if (event.key === 'Return') {
            props.onAccept();
        }

    };

    React.useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);
        return () => document.removeEventListener('keydown', handleKeyDown);
    }, []);

    if (!props.show) {
        return null;
    }

    return (
        <div className="modal">
            <div className="modal-content">
                <span className="close" onClick={props.onAccept}>&#10003;</span>
                <span> </span>
                <span className="return" onClick={props.onReturn}>&times;</span>
                {props.children}
            </div>
            <div className="supportRequest"
                 onClick={() => {
                     props.onAccept();
                     navigate('/support')
                 }}>
                For ideas and hints please click here to contact our support.
            </div>
        </div>
    );
}

export default Modal;
