import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useAuth} from "../custom_hooks/useAuth";
import {navigateToPayment} from "./paymentAdvice";
import {deleteAccount} from "../backend/theBackendInterface";
import {toast} from "react-toastify";
import Modal from "../components/modalComponent";
import DeleteAccountHelp from "../components/helpDeleteAccount";

export function Menu(props: { onShowAnswer: () => void }) {
    const {account, logout} = useAuth();
    const [showModal, setShowModal] = useState(false);
    const navigate = useNavigate();
    const {t} = useTranslation();

    const goToLogout = () => {
        logout().then(
            r => {
                toast.info(r)
            }
        );
        navigate('/');
    }

    const goToQuestion = () => {
        if (account.statistics.totalQuestions > 0) {
            navigateToPayment();
        }

        navigate('/advice');
    }

    const goToAnswer = () => {
        props.onShowAnswer();
        navigate('/answer');
    }

    const goToSupport = () => {
        navigate('/support');
    }

    const goToNewPassword = () => {
        navigate('/newPassword');
    }

    const goToStatistics = () => {
        navigate('/statistics');
    }

    const goToDeleteAccount = () => {
        deleteAccount().then(r => toast.info(t(r)));
        navigate('/');
    }

    const goToShowModal = () => {
        setShowModal(true);
    }

    const goToMainMenu = () => {
        setShowModal(false);
        navigate('/menu');
    }

    return (
        <div className="login-container">
            <Modal show={showModal} onAccept={goToDeleteAccount} onReturn={goToMainMenu}>
                <DeleteAccountHelp/>
            </Modal>
            <button onClick={goToQuestion}>{t('menu.ask_new_questions')}</button>
            <button onClick={goToAnswer}>{t('menu.get_next_unread_answer')}</button>
            <button onClick={goToStatistics}>{t('menu.show_statistics')}</button>
            <button onClick={goToSupport}>{t('menu.send_support_email')}</button>
            <button onClick={goToNewPassword}>{t('menu.set_new_password')}</button>
            <button onClick={goToLogout}>{t('general.logout')}</button>
            <button onClick={goToShowModal}>{t('general.delete_account')}</button>
        </div>
    )
}