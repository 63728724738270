import React from "react";
import {useTranslation} from "react-i18next";

export function Message(props: {msg: string, value: number}) {
    const {t} = useTranslation();
    return (
        <div className="themessage">
            <p>{t(props.msg)}</p>
            <p style={{width: "30px", textAlign: "right"}}>{props.value}</p>
        </div>
    )
}