import {useState} from 'react';
import {AnswerData, EMPTY_ANSWER} from '../backend/intefaces';
import {readNextUnreadAnswerFromDatabase, setReadByClientState} from '../backend/theBackendInterface';
import moment from 'moment';
import {useTranslation} from "react-i18next";
import {useAuth} from "./useAuth";
import { toast } from 'react-toastify';

export const useAnswer = () => {
    const { account} = useAuth();

    const [answerData, setAnswerData] = useState<AnswerData>(EMPTY_ANSWER);
    const {t} = useTranslation();

    const showAnswer = async () => {
        const response: AnswerData = await readNextUnreadAnswerFromDatabase(account);
        processReadAnswerResponse(response);
    }

    const processReadAnswerResponse = (response: AnswerData) => {
        setAnswerData({...response, account: account});
        setAnswerAsRead(response);
        showMessageBasedOnResponse(response);
    }

    const setAnswerAsRead = (response: AnswerData) => {
        if (response.id !== 0) {
            void setReadByClientState(response.id, moment());
        }
    }

    const showMessageBasedOnResponse = (response: AnswerData) => {
        if (response.id === 0) {
            toast.info(t('answerHook.no_unread_answer_found'));
        } else {
            toast.info(t('answerHook.next_answer_found.'));
        }
    }

    return {answerData, showAnswer};
}