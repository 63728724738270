import React from 'react';
import {useNavigate} from "react-router-dom";
import useFormFieldManager, {notEmpty} from "../custom_hooks/useFormFieldManager";
import {useTranslation} from "react-i18next";

export const SupportRequest = (props: { onRequest: (question: string) => void }) => {
    const {fields, updateField, getBorderColor, renderError, isFormValid} = useFormFieldManager({
        question: {value: '', error: ''},
    });
    const {t} = useTranslation();
    const navigate = useNavigate();

    const handleSendEmail = () => {
        props.onRequest(fields.question.value);
    };

    return (
        <div className="login-container">
            <div className="login-box">
                <div className="container">
                    <textarea
                        style={{borderColor: getBorderColor('question')}}
                        id="comment"
                        name="supportQuestion"
                        value={fields.question.value}
                        placeholder={t('general.enter_your_request')}
                        onChange={event => updateField('question', event.target.value, [notEmpty])}
                    />
                    {renderError('question')}
                </div>
                <div className="buttonset">
                    <button disabled={!isFormValid()} onClick={handleSendEmail}>{t('general.send_email')}</button>
                    <button onClick={() => navigate('/menu')}>{t('menu.main')}</button>
                </div>
            </div>
        </div>
    );
};
