import React from "react";
import '../css/selectLanguage.css';
import {useTranslation} from "react-i18next";

export function LanguageSelector() {
    const {i18n, t} = useTranslation();

    const getBorderStyle = (lang: string) => {
        return i18n.language === lang ? '5px solid blue' : 'none';
    };

    const onSelectLanguage = (lang: string) => {
        void i18n.changeLanguage(lang);
    }

    return (
        <div className="login-inputset">
            <div className="select-language">
                <div style={{color: "blue", paddingLeft: "10px"}}>{t('general.select')}</div>
                <div className="flag-box">
                    <img
                        src="https://upload.wikimedia.org/wikipedia/en/b/ba/Flag_of_Germany.svg"
                        alt="German Flag"
                        className="flag"
                        style={{border: getBorderStyle('DE')}}
                        onClick={() => onSelectLanguage('DE')}
                    />
                    <img
                        src="https://upload.wikimedia.org/wikipedia/en/a/ae/Flag_of_the_United_Kingdom.svg"
                        alt="English Flag"
                        className="flag"
                        style={{border: getBorderStyle('EN')}}
                        onClick={() => onSelectLanguage('EN')}
                    />
                    <img
                        src="https://upload.wikimedia.org/wikipedia/en/c/c3/Flag_of_France.svg"
                        alt="French Flag"
                        className="flag"
                        style={{border: getBorderStyle('FR')}}
                        onClick={() => onSelectLanguage('FR')}
                    />
                </div>
            </div>
        </div>
    );
}