import Cookies from "js-cookie";
import {toast} from "react-toastify";
import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query';

import React, { createContext, useContext, useState, useEffect, ReactNode } from "react";
import { processUserLogin, processUserLogout, getAccount } from "../backend/theBackendInterface";
import { AccountData, EMPTY_ACCOUNT } from "../backend/intefaces";
import { useTranslation } from "react-i18next";
import i18n from "../backend/i18n";

interface AuthContextType {
    account: AccountData;
    login: (email: string, password: string) => Promise<boolean>;
    logout: () => Promise<string>;
    updateAccount: () => Promise<void>;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider = ({ children }: { children: ReactNode }) => {

    const [account, setAccount] = useState<AccountData>(() => {
        const storedAccount = Cookies.get('account');
        return storedAccount ? JSON.parse(storedAccount) : EMPTY_ACCOUNT;
    });

    const { t } = useTranslation();

    useEffect(() => {
        Cookies.set('account', JSON.stringify(account), { expires: 7, secure: true, sameSite: 'strict' });
    }, [account]);

    const login = async (email: string, password: string): Promise<boolean> => {
        const response: AccountData = await processUserLogin(email, password);
        showMessageBasedOnResponse(response);
        void i18n.changeLanguage(response.language);
        return processUserLoginResponse(response);
    };

    const updateAccount = async () => {
        const response = await getAccount();
        showMessageBasedOnResponse(response);
        void processUserLoginResponse(response);
    };

    const showMessageBasedOnResponse = (response: AccountData) => {
        if (response.id === 0) {
            toast.error(t('general.login_failed'));
        } else {
            toast.success(response.name + ' ' + t('general.login_success'));
        }
    };

    const processUserLoginResponse = (response: AccountData): boolean => {
        const result = response.id > 0;
        if (result) {
            setAccount(response);
        }
        return result;
    };

    const logout = async ():Promise<string> => {
        const response = await processUserLogout();
        setAccount(EMPTY_ACCOUNT);
        Cookies.remove('account');
        return response;
    };

    return (
        <AuthContext.Provider value={{ account, login, logout, updateAccount }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = (): AuthContextType => {
    const context = useContext(AuthContext);
    if (context === undefined) {
        throw new Error("useAuth must be used within an AuthProvider");
    }
    return context;
};
