import React from 'react';
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {z} from "zod";
import {FieldError, useForm, UseFormRegister} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";

const forgotPasswordSchema = () => z.object({
    email: z.string().min(1, "validation.email_required").email("validation.email_format")
});

type ForgotPasswordFormInputs = z.infer<ReturnType<typeof forgotPasswordSchema>>;

export const ForgotPwd = (props: {onEmail: (email:string) => void}) => {

    const navigate = useNavigate();

    const {
        register,
        handleSubmit,
        formState: {errors, isValid}
    } = useForm<ForgotPasswordFormInputs>({
        resolver: zodResolver(forgotPasswordSchema()),
        mode: 'onChange'
    });

    const onSubmit = (data: ForgotPasswordFormInputs) => {

        props.onEmail(data.email);

        returnToLogin();
    };

    const returnToLogin = () => {
        navigate('/');
    }

    return (
        <form className="login-container" onSubmit={handleSubmit(onSubmit)}>
            <h1>Reset Password</h1>
            <div className="login-box">

                <EmailInput
                    register={register}
                    name="email"
                    type="text"
                    placeholder='general.email'
                    error={errors.email}
                />

                <div className="buttonset">
                    <Button type="submit" disabled={!isValid} label='general.send_email' />
                    <Button type="button" onClick={returnToLogin} label='general.back' />
                </div>

            </div>
        </form>
    );
}

interface InputProps {
    register: UseFormRegister<ForgotPasswordFormInputs>;
    name: keyof ForgotPasswordFormInputs;
    type: string;
    placeholder: string;
    error?: FieldError;
}

const EmailInput: React.FC<InputProps> = ({ register, name, type, placeholder, error }) => {
    const { t } = useTranslation();

    return (
        <div className="register-inputset">
            <input
                {...register(name)}
                type={type}
                placeholder={t(placeholder)}
                style={{ borderColor: error ? 'red' : '#ddd' }}
            />
            {error && <span style={{ color: "red" }}>{error.message && t(error.message)}</span>}
        </div>
    );
};

interface ButtonProps {
    type: 'button' | 'submit';
    onClick?: () => void;
    disabled?: boolean;
    label: string;
}

const Button: React.FC<ButtonProps> = ({ type, onClick, disabled, label }) => {
    const { t } = useTranslation();

    return (
        <button type={type} onClick={onClick} disabled={disabled}>
            {t(label)}
        </button>
    );
};
