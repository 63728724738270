import moment from "moment/moment";

export interface AccountData {
    id: number;
    name: string;
    email: string;
    rules: string[];
    language: string;
    statistics: StatisticalData;
}

export const EMPTY_ACCOUNT : AccountData = {
    id: 0,
    email: '',
    rules: [''],
    name: '',
    language: '',
    statistics: {
        totalQuestions: 0,
        totalUnansweredQuestions: 0,
        totalUnreadAnswers: 0
    }
};

export const EMPTY_ANSWER : AnswerData = {
    id: 0,
    account: EMPTY_ACCOUNT,
    questions: [''],
    numbers: [],
    verseName: '',
    verseText: '',
    advice: '',
    videoInfo: '',
    videoLink: '',
    answeringProphet: '',
    answerDateTimestamp: moment(),
    readByClientTimeStamp: moment()
};

export interface EmailClientData {
    newUser: string;
    password: string;
    vorname: string;
    nachname: string;
    email: string;
    language: string;
}

export interface StatisticalData {
    totalQuestions: number;
    totalUnreadAnswers: number;
    totalUnansweredQuestions: number;
}

export interface LoginResponse {
    error: boolean;
    msg: string;
    table: AccountData;
}

export interface AnswerData {
    id: number,
    account: AccountData,
    questions: string[],
    numbers: number[],
    verseName: string,
    verseText: string,
    advice: string,
    videoInfo: string,
    videoLink: string,
    answeringProphet: string,
    answerDateTimestamp: moment.Moment,
    readByClientTimeStamp: moment.Moment
}

export interface AnswerFromDatabase {
    id: number;
    clientId: number;
    question1: string;
    question2: string;
    question3: string;
    answer1: number;
    answer2: number;
    answer3: number;
    verseName: string;
    verseText: string;
    advice: string;
    videoInfo: string;
    video: string;
    prophet: string;
    answered: bigint;
    readByClient: bigint;

}

export interface clientQuestionsToSendToDatabase {
    clientId: number;
    question1: string;
    question2: string;
    question3: string;
    created: number;
}

export interface FieldState {
    value: string;
    error?: string;
}

export interface FieldDictionary {
    [key: string]: FieldState;
}

export interface EmailData {
    email: string,
    subject: string,
    message: string
}