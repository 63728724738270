import QuestionItem from "../components/questionItem";
import React from "react";

export function ListOfQuestions(props: {
    questions: string[],
    numbers: number[],
    withDelete: boolean,
    removeQuestion: (question: string) => void
}) {

    return (
        <div className="question-list">
            {props.questions.map((question, index) => (
                <QuestionItem key={index}
                              question={question}
                              number={props.numbers[index]}
                              withDelete={props.withDelete}
                              remove={props.removeQuestion}
                />
            ))}
        </div>
    )
}