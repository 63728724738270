import React from 'react';
import '../css/welcomeGraphic.css';

const WelcomeGraphic = () => {
    return (
        <div className="welcomeGraphic">
            <svg width="100%" height="100%" viewBox="0 0 500 150">
                <text x="10" y="20" className="small">Welcome</text>
                <text x="40" y="50" className="medium">Bienvenue</text>
                <text x="40" y="100" className="large">Willkommen</text>
                <text x="20" y="30" className="small">Bienvenido</text>
                <text x="60" y="60" className="medium">Benvenuto</text>
                <text x="100" y="90" className="large">欢迎</text>
                <text x="140" y="120" className="small">ようこそ</text>
                <text x="180" y="30" className="medium">환영합니다</text>
                <text x="220" y="60" className="large">Добро пожаловать</text>
                <text x="260" y="90" className="small">Hoş geldiniz</text>
                <text x="300" y="120" className="medium">Bem-vindo</text>
                <text x="340" y="30" className="large">स्वागत है</text>
                <text x="380" y="60" className="small">مرحبا</text>
            </svg>
        </div>
    );
};

export default WelcomeGraphic;
