import { sendQuestionsToDatabase } from '../backend/theBackendInterface';
import {useAuth} from "./useAuth";
import {toast} from "react-toastify";

export const useQuestions = () => {

    const {account} = useAuth();

    const sendQuestions = async (questions: string[]) => {
        const response = await sendQuestionsToDatabase(account.id, questions);
        console.log('account ', account);
        toast.info(response);
    }

    return {sendQuestions};
}