import '../css/userLogin.css';
import {useNavigate} from "react-router-dom";
import React, {useEffect} from 'react';
import {FieldError, useForm, UseFormRegister} from 'react-hook-form';
import {zodResolver} from '@hookform/resolvers/zod';
import {z} from 'zod';
import {useTranslation} from "react-i18next";
import {useAuth} from "../custom_hooks/useAuth";
import {LanguageSelector} from "../components/selectLanguage";

// Define the schema using zod
const loginSchema = () => z.object({
    username: z.string()
        .min(1, "validation.username_required")
        .max(16, "validation.username_max_length"),
    password: z.string()
        .min(1, "validation.password_required")
});

type LoginFormInputs = z.infer<ReturnType<typeof loginSchema>>;

export const UserLogin = () => {
    const {login} = useAuth();
    const navigate = useNavigate();

    const {
        register,
        handleSubmit,
        formState: {errors, isValid}
    } = useForm<LoginFormInputs>({
        resolver: zodResolver(loginSchema()),
        mode: 'onChange'
    });

    useEffect(() => {
        if (window.location.href.includes('page=questions')) {
            navigate('/questions');
        }
        // eslint-disable-next-line
    }, []);

    const sendLogin = async (data: LoginFormInputs) => {
        const result = await login(data.username, data.password);
        navigateToNextPageBasedOnLogin(result);
    }

    const navigateToNextPageBasedOnLogin = (result: boolean) => {
        if (result) {
            navigate('/menu');
        } else {
            navigate('/');
        }
    }

    return (
        <form onSubmit={handleSubmit(sendLogin)} className="login-container">

            <LanguageSelector/>

            <Input
                register={register}
                name="username"
                type="text"
                placeholder='general.username'
                error={errors.username}
            />

            <Input
                register={register}
                name="password"
                type="password"
                placeholder='general.password'
                error={errors.password}
            />

            <SubmitButton isValid={isValid}/>

            <GoToForgotPasswordButton/>

            <GoToRegisterButton/>

        </form>
    );
};

interface InputProps {
    register: UseFormRegister<LoginFormInputs>;
    name: 'username' | 'password';
    type: string;
    placeholder: string;
    error?: FieldError;
}

const Input: React.FC<InputProps> = ({ register, name, type, placeholder, error }) => {
    const { t } = useTranslation();

    return (
        <div className="login-inputset">
            <input
                {...register(name)}
                type={type}
                placeholder={t(placeholder)}
                style={{borderColor: error ? 'red' : '#ddd'}}
            />
            {error && <span>{error.message && t(error.message)}</span>}
        </div>
    );
};

interface SubmitButtonProps {
    isValid: boolean;
}

const SubmitButton: React.FC<SubmitButtonProps> = ({ isValid }) => {
    const { t } = useTranslation();

    return (
        <div className="login-inputset">
            <button type="submit" disabled={!isValid}>{t('general.login')}</button>
        </div>
    );
};

function GoToForgotPasswordButton() {
    const {t} = useTranslation();
    const navigate = useNavigate();
    return (
        <div className="login-inputset">
            <button type="button" onClick={() => navigate('/password')}>
                {t('general.forgot_password')}
            </button>
        </div>
    )
}

function GoToRegisterButton() {
    const {t} = useTranslation();
    const navigate = useNavigate();
    return (
        <div className="login-inputset">
            <button type="button" onClick={() => navigate('/register')}>
                {t('general.register')}
            </button>
        </div>
    )
}