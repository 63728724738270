import React from "react";

export function VideoLink(props: { link?: string, info?: string}) {

    return (
        <div className="video-link">
            {props.link && <a href={props.link} target="_blank" rel="noopener noreferrer">
                {props.info}
            </a>}
        </div>
    )
}

