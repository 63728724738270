import React from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from "react-router-dom";

export const navigateToPayment = () => {
    // Hier fügst du deine URL ein
    const paymentUrl = "https://buy.stripe.com/test_5kA6qzenE1qN95S000";

    // Verwendung von window.location zur externen Navigation
    window.location.href = paymentUrl;
};


const PaymentAdvice: React.FC = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();

    const returnToMenu = () => {
        navigate('/menu');
    }

    return (
        <div className="login-container">

            <h1>{t('advice.headline')}</h1>
            <p>{t('advice.main_text')}</p>
            <h4>{t('advice.footer')}</h4>
            <br/>

            <h2>{t('advice.accept')}</h2>
            <h2>{t('advice.understood')}</h2>
            <button onClick={navigateToPayment}>{t('advice.pay')}</button>
            <button onClick={returnToMenu}>{t('menu.main')}</button>

        </div>
    );
};

export default PaymentAdvice;