import {Message} from "../components/message";
import React from "react";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {useAuth} from "../custom_hooks/useAuth";

export const Statistics = () => {
    const { account } = useAuth();
    const {totalQuestions, totalUnansweredQuestions, totalUnreadAnswers} = account.statistics;
    const {t} = useTranslation();
    const navigate = useNavigate();

    const goToMenu = () => {
        navigate('/menu');
    }

    return (
        <div className="login-container">
            <p>{t('stats.statistics')}</p>
            <Message msg={t('stats.total_questions')} value={totalQuestions} />
            <Message msg={t('stats.unread_answers')} value={totalUnreadAnswers}/>
            <Message msg={t('stats.unanswered_questions')} value={totalUnansweredQuestions}/>
            <div>
                <button onClick={goToMenu}>{t('menu.main')}</button>
            </div>
        </div>
    );
}