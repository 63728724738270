import React from "react";
import {useNavigate} from "react-router-dom";
import '../css/headAndFoot.css';
import {useTranslation} from "react-i18next";
import WelcomeGraphic from "./welcomeGraphic";

export function Header() {
    return (
        <div className="header">
            <WelcomeGraphic/>
        </div>
    )
}


export const Footer = () => {
    const navigate = useNavigate();
    const {t} = useTranslation();

    const goToLegal = () => {
        navigate('/legal');
    }

    return (
        <div className="footer">
            <div className="footerLine">
                <h5>&#169; J. Walther 2024</h5>
                <button
                    className="footButton"
                    onClick={goToLegal}>{t('legal.legal')}
                </button>
            </div>
        </div>
    )
}

