import {useNavigate} from "react-router-dom";
import React from 'react';
import {EmailClientData} from "../backend/intefaces";
import {useTranslation} from "react-i18next";
import i18n from "../backend/i18n";
import {generateRandomHash} from "../backend/theBackendInterface";
import {FieldError, useForm, UseFormRegister} from 'react-hook-form';
import {zodResolver} from '@hookform/resolvers/zod';
import {z} from 'zod';

const registerSchema = () => z.object({
    nachname: z.string()
        .min(1, "validation.last_name_required"),
    vorname: z.string()
        .min(1, "validation.first_name_required"),
    username: z.string()
        .min(1, "validation.username_required")
        .min(8, "validation.username_min_length")
        .max(16, "validation.username_max_length"),
    email: z.string()
        .min(1, "validation.email_required")
        .email("validation.email_format")
});

type RegisterFormInputs = z.infer<ReturnType<typeof registerSchema>>;

export const Register = (props: { onRegister: (fd: EmailClientData) => void }) => {
    const navigate = useNavigate();

    const {
        register,
        handleSubmit,
        formState: {errors, isValid}
    } = useForm<RegisterFormInputs>({
        resolver: zodResolver(registerSchema()),
        mode: 'onChange'
    });

    const onSubmit = (data: RegisterFormInputs) => {

        const hashPassword = generateRandomHash(12);

        props.onRegister({
            nachname: data.nachname,
            vorname: data.vorname,
            newUser: data.username,
            email: data.email,
            password: hashPassword,
            language: i18n.language
        });

        navigate('/');
    };

    const returnToLogin = () => {
        navigate('/');
    }

    return (
        <form className="login-container" onSubmit={handleSubmit(onSubmit)}>
            <Input
                register={register}
                name="nachname"
                type="text"
                placeholder='general.last_name'
                error={errors.nachname}
            />

            <Input
                register={register}
                name="vorname"
                type="text"
                placeholder='general.first_name'
                error={errors.vorname}
            />

            <Input
                register={register}
                name="username"
                type="text"
                placeholder='general.username'
                error={errors.username}
            />

            <Input
                register={register}
                name="email"
                type="email"
                placeholder='general.email'
                error={errors.email}
            />

            <div className="buttonset">
                <Button type="submit" disabled={!isValid} label='general.register'/>
                <Button type="button" onClick={returnToLogin} label='general.back'/>
            </div>
        </form>
    );
};

interface InputProps {
    register: UseFormRegister<RegisterFormInputs>;
    name: keyof RegisterFormInputs;
    type: string;
    placeholder: string;
    error?: FieldError;
}

const Input: React.FC<InputProps> = ({register, name, type, placeholder, error}) => {
    const {t} = useTranslation();

    return (
        <div className="login-inputset">
            <input
                {...register(name)}
                type={type}
                placeholder={t(placeholder)}
                style={{borderColor: error ? 'red' : '#ddd'}}
            />
            {error && <span style={{color: "red"}}>{error.message && t(error.message)}</span>}
        </div>
    );
};

interface ButtonProps {
    type: 'button' | 'submit';
    onClick?: () => void;
    disabled?: boolean;
    label: string;
}

const Button: React.FC<ButtonProps> = ({type, onClick, disabled, label}) => {
    const {t} = useTranslation();

    return (
        <button type={type} onClick={onClick} disabled={disabled}>
            {t(label)}
        </button>
    );
};
