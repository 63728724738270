import React from 'react';
import {Verse} from "../components/verse";
import {Advice} from "../components/advice";
import {ListOfQuestions} from "../components/listOfQuestions";
import {VideoLink} from "../components/videoLink";
import {AnswerData} from "../backend/intefaces";
import {PrivatMenu} from "../components/privateMenu";
import {useAuth} from "../custom_hooks/useAuth";

export function DisplayAnswer(props: {answer: AnswerData, onSendReport: (answer: AnswerData) => void}) {

    const { updateAccount } = useAuth();

    const answer = props.answer;

    const dummy = () => {}

    if (answer.id === 0) {
        return (
            <div className="login-container">
                <PrivatMenu answer={answer} onReturnToMain={updateAccount} onSendReport={props.onSendReport}/>
            </div>
        )
    }

    return (
        <div className="login-container">
            <ListOfQuestions questions={answer.questions}
                             numbers={answer.numbers}
                             withDelete={false}
                             removeQuestion={dummy}
            />
            <Verse verseName={answer.verseName} verseText={answer.verseText}/>
            <Advice advice={answer.advice}/>
            <VideoLink link={answer.videoLink} info={answer.videoInfo}/>
            <PrivatMenu answer={props.answer} onReturnToMain={updateAccount} onSendReport={props.onSendReport}/>
        </div>
    );
}

