import React, {useEffect} from 'react';
import './css/App.css';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import {Footer, Header} from "./components/headAndFoot";
import {UserLogin} from "./pages/userLogin";
import {Register} from "./pages/register";
import {ForgotPwd} from "./pages/forgotPWD";
import {SupportRequest} from "./pages/supportRequest";
import {DisplayAnswer} from "./pages/displayAnswer";
import {Questions} from "./pages/questions";
import {Menu} from "./pages/menu";
import {NewPwd} from "./pages/newPassword";
import {useAnswer} from "./custom_hooks/useAnswer";
import {useQuestions} from "./custom_hooks/useQuestions";
import {usePasswordManagement} from "./custom_hooks/usePassword";
import {useRegistration} from "./custom_hooks/useRegistration";
import {useSendEmails} from "./custom_hooks/useSendEmails";
import {Legal} from "./pages/legal";
import {Statistics} from "./pages/statistics";
import {Rules} from "./pages/rules";
import {Handbook} from "./pages/handbook";
import PaymentAdvice from "./pages/paymentAdvice";

function App() {
    const {answerData, showAnswer} = useAnswer();
    const {sendQuestions} = useQuestions();
    const {resetPasswordToDefault, registerNewPassword} = usePasswordManagement();
    const {registerNewClient} = useRegistration();
    const {sendHelpEmail, sendSummaryEmail} = useSendEmails();

    useEffect(() => {
        document.title = "Prophetical App";
    }, []);

    return (
        <div className="App">
            <Router basename={process.env.NODE_ENV === 'development' ? '/' : '/Prophet_Client_App/'}>
                <Header/>
                <Routes>
                    <Route path="/" element={<UserLogin/>}/>
                    <Route path="/menu" element={<Menu onShowAnswer={showAnswer}/>}/>
                    <Route path="/answer" element={<DisplayAnswer answer={answerData} onSendReport={sendSummaryEmail}/>}/>
                    <Route path="/register" element={<Register onRegister={registerNewClient}/>}/>
                    <Route path="/password" element={<ForgotPwd onEmail={resetPasswordToDefault}/>}/>
                    <Route path="/support" element={<SupportRequest onRequest={sendHelpEmail}/>}/>
                    <Route path="/questions" element={<Questions onSendQuestions={sendQuestions}/>}/>
                    <Route path="/newpassword" element={<NewPwd onNewPwd={registerNewPassword}/>}/>
                    <Route path="/legal" element={<Legal/>}/>
                    <Route path="/statistics" element={<Statistics/>}/>
                    <Route path="/rules" element={<Rules/>}/>
                    <Route path="/handbook" element={<Handbook/>}/>
                    <Route path="/advice" element={<PaymentAdvice/>}/>
                </Routes>
                <Footer/>
            </Router>
        </div>
    );
}

export default App;